<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Litter</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="weight-form">
          <v-text-field
            label="Name"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-select
            label="Breed"
            v-model="fields.breed_id"
            :items="breeds"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('breed_id')"
            :error-messages="errors['breed_id']"
          ></v-select>

          <v-text-field
            label="Grade"
            v-model="fields.grade"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('grade')"
            :error-messages="errors['grade']"
          ></v-text-field>

          <v-text-field
            label="Dob"
            v-model="fields.dob"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('dob')"
            :error-messages="errors['dob']"
          ></v-text-field>

          <v-select
            label="Size"
            v-model="fields.size_id"
            :items="sizes"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('size_id')"
            :error-messages="errors['size_id']"
          ></v-select>

          <v-autocomplete
            label="Colour"
            v-model="selectedColors"
            :items="colors"
            item-text="name"
            item-value="id"
            multiple
            outlined
            chips
            background-color="white"
            :error="errors.hasOwnProperty('colors')"
            :error-messages="errors['colors']"
          ></v-autocomplete>

          <v-select
            label="Markings"
            v-model="fields.marking_id"
            :items="markings"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('marking_id')"
            :error-messages="errors['marking_id']"
          ></v-select>

          <v-select
            label="Coat Type"
            v-model="fields.coat_type_id"
            :items="coat_types"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('coat_type_id')"
            :error-messages="errors['coat_type_id']"
          ></v-select>

          <!-- <v-text-field
            label="Total Girls"
            v-model="fields.total_girls"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_girls')"
            :error-messages="errors['total_girls']"
          ></v-text-field> -->

          <v-text-field
            v-if="
              this.litter.status == 'Planned' || this.litter.status == 'Due'
            "
            label="# Puppies (Predicted)"
            v-model="fields.predicted"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('predicted')"
            :error-messages="errors['predicted']"
          ></v-text-field>

          <!-- <v-text-field
            label="Total Boys"
            v-model="fields.total_boys"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_boys')"
            :error-messages="errors['total_boys']"
          ></v-text-field> -->

          <v-text-field
            label="Total Available"
            v-model="fields.total_available"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_available')"
            :error-messages="errors['total_available']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="weight-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    litter: {
      required: true,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null,
    },
  },
  data() {
    return {
      fields: {
        name: null,
        breed_id: null,
        grade: null,
        location: null,
        marking_id: null,
        dob: null,
        colors: [],
        size_id: null,
        coat_type_id: null,
        predicted: null,
        total_girls: null,
        total_boys: null,
        total_available: null,
      },
      statuses: [
        { name: "Breeding Litter", value: "Breeding Litter" },
        { name: "Speyed", value: "Speyed" },
        { name: "Breeding Complete", value: "Breeding Complete" },
        { name: "Puppy", value: "Puppy" },
        { name: "External", value: "External" },
      ],
      locations: [
        { name: "LHL", value: "LHL" },
        { name: "FCH", value: "FCH" },
        { name: "Breeder Colleague", value: "Breeder Colleague" },
        { name: "Customer", value: "Customer" },
        { name: "External", value: "External" },
      ],
      genders: [
        { name: "Female", value: "F" },
        { name: "Male", value: "M" },
      ],

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    selectedColors: {
      get() {
        return this.fields.colors.map((e) => {
          return e;
        });
      },

      set(colors) {
        this.fields.colors = colors.filter((e) => {
          return colors.includes(e);
        });
      },
    },

    breeds() {
      return this.$store.state.lhl.breeds["breeds"];
    },

    coat_types() {
      let types = this.$store.state.lhl.dogs["coat_types"];

      types = types.filter((a) => {
        return a.breed_id == this.litter.breed_id;
      });

      return types;
    },

    sizes() {
      let sizes = this.$store.state.lhl.dogs["sizes"];

      sizes = sizes.filter((a) => {
        return a.breed_id == this.litter.breed_id;
      });

      return sizes;
    },

    colors() {
      let colors = this.$store.state.lhl.dogs["colors"];

      colors = colors.filter((a) => {
        return a.breed_id == this.litter.breed_id;
      });

      return colors;
    },

    markings() {
      let markings = this.$store.state.lhl.dogs["markings"];

      if (markings) {
        markings = markings.filter((a) => {
          return a.breed_id == this.litter.breed_id;
        });
      }

      return markings;
    },
  },

  methods: {
    openForm: function (litter = null) {
      if (litter !== null) {
        this.litter = litter;

        this.fields.name = litter.name;

        this.fields.grade = litter.grade;
        this.fields.location = litter.location;
        this.fields.dob = litter.dob;

        this.fields.predicted = litter.predicted;

        this.fields.breed_id = litter.breed_id ? litter.breed.id : null;
        this.fields.size_id = litter.size_id ? litter.size.id : null;
        this.fields.marking_id = litter.marking_id ? litter.marking.id : null;
        this.fields.coat_type_id = litter.coat_type_id
          ? litter.coat_type.id
          : null;

        // this.fields.total_girls = litter.total_girls;
        // this.fields.total_boys = litter.total_boys;
        this.fields.total_available = litter.total_available;

        this.fields.colors = litter.colors.map((c) => c.breed_colour_id);

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        litterId: this.$route.params.litterId,
        fields: this.fields,
      };

      payload.fields.formName = "basic";

      this.$store
        .dispatch("lhl/litters/saveBasic", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.name = null;
      this.fields.breed_id = null;
      this.fields.grade = null;
      this.fields.location = null;
      this.fields.marking_id = null;
      this.fields.dob = null;
      this.fields.size_id = null;
      this.fields.coat_type_id = null;
      this.fields.total_boys = null;
      this.fields.predicted = null;
      this.fields.total_available = null;
      this.fields.colors = [];
    },
  },
};
</script>
